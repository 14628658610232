import React from "react";
import { Wrapper, Content } from "./style";

import config from "config.json";

// import logo from "assets/img/logo_white.png";
import bg from "assets/img/bg.jpg";
import Documento from "assets/RMC-Codigo-de-Conduta.pdf";

//Components
import Background from "components/Background";

//3rd party
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import Menu from "components/Menu";
import { useState } from "react";
import { useEffect } from "react";
import anime from "animejs";

export default function(props) {
  const [localState, setLocalState] = useState({ isOpen: false });

  useEffect(() => {
    const tgt = document.getElementsByClassName("_menu_ref")[0];

    if (!tgt) {
      return;
    }

    const toggleMenu = () => {
      // //console.log("clicou");
      setLocalState({ ...localState, isOpen: !localState.isOpen });
    };

    tgt.addEventListener("click", toggleMenu);

    return () => {
      tgt.removeEventListener("click", toggleMenu);
    };
  }, [localState.isOpen]);

  useEffect(() => {
    anime
      .timeline({
        easing: `easeInOutQuad`,
        // delay: (el, i) => i * 100
      })
      .add({
        targets: [".rowUp.first"],
        translateY: [50, 0],
        scale: [1.1, 1],
        opacity: [0, 1],
      })
      .add(
        {
          targets: [".rowUp:not(.first)"],
          translateY: [50, 0],
          scale: [1.1, 1],
          opacity: [0, 1],
        },
        `-=500`
      )
      .play();
  }, []);

  return (
    <>
      <Background image={bg}>
        <Wrapper>
          <Container fluid>
            <Row
              style={{
                top: 0,
                position: `fixed`,
                width: `100%`,
                zIndex: !!localState.isOpen ? 99999 : 10,
              }}
            >
              <Menu></Menu>
            </Row>
            <div id="conteudo">
              <Row className="text-center mt-0 mt-md-5">
                <Col>
                  <Content.mainTitle>NOSSO CÓDIGO DE CONDUTA</Content.mainTitle>
                  <Container>
                    <Row>
                      <Col className="col-12 col-md-6 offset-0 offset-md-3">
                        <Content.subtitle>
                          Espero que tenha uma excelente leitura!
                        </Content.subtitle>
                      </Col>
                    </Row>
                    <Row className="text-justify">
                      <Col className="col-12 col-md-6 offset-0 offset-md-3"></Col>
                    </Row>
                      <iframe
                        allow="autoplay; fullscreen"
                        scrolling="auto"
                        src={Documento}
                      ></iframe>
                      <div className="btDoc">
                      <Button
                          onClick={() => {
                            window.open(
                              Documento,
                              "_blank"
                            );
                          }}
                          className="p-4 btn-sm btNovaJanela"
                        >
                          ABRIR EM NOVA JANELA
                        </Button>
                      </div>
                      {/* <Row className="mt-3">
                        <Col style={{ position: `relative` }}>
                          <Content.FloatingBar />
                          <Row className="p-5 text-center">
                            <Col>
                              <Button
                                onClick={() => {
                                  window.open(
                                    Documento,
                                    "_blank"
                                  );
                                }}
                                className="p-4 btn-lg"
                              >
                                CLIQUE AQUI E DEIXE-NOS SEU CURRÍCULO
                              </Button>
                            </Col>
                          </Row>
                          <Content.FloatingBar end />
                        </Col>
                      </Row> */}
                  </Container>
                  {/* <Row className="rowUp">
                    <Col>
                      <Content.diretoria>
                        <Container>
                          <Row>
                            <Col className="col-12 offset-0 col-lg-6 offset-lg-3">
                              <Row>
                                <Col className="col-12 col-lg-4 mb-5 mb-lg-0">
                                  <Moldura
                                    adjust={`60% 4%`}
                                    name="Ueze Elias Zahran"
                                    description="Fundador do Grupo Zahran"
                                    obs="In Memorian"
                                    image={Fundador}
                                  />
                                </Col>
                                <Col className="col-12 col-lg-4 mb-5 mb-lg-0">
                                  <Moldura
                                    adjust={`42% 4%`}
                                    name="Caio Turqueto"
                                    description="Presidente do Grupo Zahran"
                                    image={Presidente}
                                  />
                                </Col>
                                <Col className="col-12 col-lg-4 mb-5 mb-lg-0">
                                  <Moldura
                                    adjust={`65% 4%`}
                                    name="Nicomedes Silva Filho"
                                    description="Diretor Geral RMC"
                                    image={Diretor}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Container>
                      </Content.diretoria>
                    </Col>
                  </Row>
                  <Container>
                    <Row className="text-justify" style={{ marginTop: 20 }}>
                      <Col className="col-12 col-md-6 offset-0 offset-md-3">
                        <Content.paragraph>
                          Com trabalho desenvolvido graças à competência e
                          dedicação de nossos {config.dados.colaboradores}{" "}
                          colaboradores. Mais do que o cumprimento de metas,
                          nossa atuação retrata nosso compromisso maior:{" "}
                          <span>
                            atuar em prol do desenvolvimento de nossa região.
                          </span>
                        </Content.paragraph>
                        <Content.paragraph>
                          Desde nossa fundação, testemunhamos e noticiamos o
                          acontecer da história de nosso país e de nossa região.
                          Política, economia, comunidade, a divisão do Estado, o
                          desbravar da agricultura, o <i>boom</i> das{" "}
                          <i>commodities</i>, a construção de estradas, o
                          caminhar do desenvolvimento. Mato Grosso e Mato Grosso
                          do Sul registrados e apresentados em imagem e som.
                        </Content.paragraph>
                        <Content.paragraph>
                          {" "}
                          Por meio do pilar de nosso negócio, a comunicação,
                          conseguimos crescer e consolidar nossa presença e
                          aceitação. Com isso, passamos a atuar não somente nas
                          capitais, mas também no interior. Porque crescer está
                          no nosso DNA. Crescer de forma sustentável,
                          responsável e bem alicerçada.
                        </Content.paragraph>
                      </Col>
                    </Row>
                  </Container> */}
                </Col>
              </Row>
              <Row>
                <Col className="col-10 offset-1">
                  <h1> </h1>
                </Col>
              </Row>
            </div>
          </Container>
        </Wrapper>
      </Background>
    </>
  );
}


