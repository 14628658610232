//import React from "react";
import styled from "styled-components";
//import { Container } from "react-bootstrap";
import config from "config.json";

//const pattern = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAEElEQVQImWNgYGD4z0AZAABABAEAuUSdjgAAAABJRU5ErkJggg==`;

export const Wrapper = styled.div`
  font-family: "Futura";
  text-shadow: 0px 2px 3px #0005;
  height: 100%;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0;
  }

  scrollbar-width: none;

  & #conteudo {
    margin-top: 150px;
    & .container{
      height: calc(100vh - 300px);
      @media (max-width: 768px) {
        height: auto;
      }
    }
    & .btDoc{
      margin:20px 0 ;
      @media (max-width: 768px) {
        margin: 50px;
      }
    }
    & iframe{
      border: 0;
      display: block;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0);
      border-radius: 10px;
      box-shadow: 1px 1px 13px 9px rgba(0, 0, 0, 0.8);
      @media (max-width: 768px) {
        display: none;
      }
    }
    &::-webkit-scrollbar {
      width: 0;
    }

    scrollbar-width: none;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 150px);
    z-index: 2;
    /* padding-top: 150px; */
  }
`;

export const Content = {};

Content.mainTitle = styled.h1.attrs({ className: "rowUp first" })`
  font-family: "Gotham Title";
  color: #fff;
  font-size: 2.25em;
  @media (min-width: 576px) {
    font-size: 3.5em;
  }
`;

Content.subtitle = styled.p.attrs({ className: "rowUp first" })`
  font-family: "Gotham";
  color: #fff;
  text-transform: uppercase;
  font-size: 0.7em;
  margin-bottom: 100px;
  letter-spacing: 1px;
  &::after {
    content: "";
    position: absolute;
    width: 33%;
    height: 20px;
    top: 50px;
    right: 0;
    background: ${config.theme.primary};
  }
`;

Content.paragraph = styled.p.attrs({ className: "rowUp" })`
  color: #fff;
  font-family: "Gotham Text";
  letter-spacing: 1px;
  text-indent: 1cm;

  &:not(:last-child) {
    padding-bottom: 20px;
  }

  & > span {
    display: block;
    padding: 20px;
    background: #2229;
    text-indent: 0;
    text-transform: uppercase;
    font-family: "Futura";
    margin-top: 20px;
  }
`;

Content.diretoria = styled.div`
  background: ${config.theme.primary};
  color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
`;

let size = `170px`;

Content.FotoRedonda = styled.div`
  width: ${size};
  height: ${size};
  margin: 0 auto;
  border-radius: 100%;
  background: ${props => `url(${props.url})`};
  background-size: 150%;
  background-position: ${props => `${props.adjust}`};
  margin-bottom: 10px;
  border: 5px solid #fff5;
`;

Content.NomeDiretoria = styled.h4`
  font-family: "Gotham Title";
  font-size: 10pt;
  white-space: nowrap;
  margin: 0;
`;

Content.CargoDiretoria = styled.p`
  font-size: 0.8em;
  div{
    font-style:italic;
  }
`;
