import React, { useEffect/* , useState */ } from "react";
import Helmet from 'react-helmet';
import "./index.css";

import { Switch, BrowserRouter, Route } from "react-router-dom";

// import Page from './components/Page/Page';
import Loading from "util/Loading";
import Home from "pages/Home/Home";
import About from "pages/About";
import CodigoConduta from "pages/CodigoConduta";
import Contato from "pages/Contato";
import Cobertura from "pages/Cobertura";
import Comercial from "pages/Comercial";
import SinalDigital from "pages/SinalDigital";
import Marcas from "pages/Marcas";
import ErrorPage from "pages/Error";
import Trabalhe from "pages/Trabalhe";
import Radio from "exception/Radio";
import {/*  useSelector,  */useDispatch } from "react-redux";

const Error_Page = props => <ErrorPage code={500} {...props} />;

export default function App() {
  //const isLoading = useSelector(state => state.project.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "PROJECT.LOADING.OFF" });
  }, []);
  console.log(window.location.href.indexOf('localhosaast')>0 && 'ok');

  

  return (
    <>
        {window.location.href.indexOf('grupormc.com.br')>0? 
          (
          <Helmet>
              <meta name="adopt-website-id" content="f26d7bcf-74c7-4d03-b787-9826c3955c1c"/>
              <script src="//tag.goadopt.io/injector.js?website_code=f26d7bcf-74c7-4d03-b787-9826c3955c1c" class="adopt-injector"></script>
          </Helmet>
          ):('')
        }
        {window.location.href.indexOf('morena.com.vc')>0? 
          (
          <Helmet>
              <meta name="adopt-website-id" content="6b4720d1-6f2c-4080-840c-7d051ed59c7e" />
              <script src="//tag.goadopt.io/injector.js?website_code=6b4720d1-6f2c-4080-840c-7d051ed59c7e" class="adopt-injector"></script>
          </Helmet>
          ):('')
        }
        {window.location.href.indexOf('centroamerica.com.vc')>0? 
          (
          <Helmet>
              <meta name="adopt-website-id" content="6004513c-351d-43aa-9fbe-2f9135d705f8" />
              <script src="//tag.goadopt.io/injector.js?website_code=6004513c-351d-43aa-9fbe-2f9135d705f8" class="adopt-injector"></script>
          </Helmet>
          ):('')
        }
      <Loading />
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/radio-morena-fm" exact component={Radio}></Route>
          <Route path="/a-rmc" exact component={About}></Route>
          <Route path="/codigo-de-conduta" exact component={CodigoConduta}></Route>
          <Route path="/cobertura" exact component={Cobertura}></Route>
          <Route path="/comercial" exact component={Comercial}></Route>
          <Route path="/sinaldigital" exact component={SinalDigital}></Route>
          <Route path="/nossas-marcas" exact component={Marcas}></Route>
          <Route path="/fale-conosco" exact component={Contato}></Route>
          <Route path="/trabalhe-conosco" exact component={Trabalhe}></Route>
          <Route path="/" render={() => <ErrorPage code={404} />}></Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

// const Error404 = props => {
//     //console.log(props);
//     return (<Page> <span>A página que você tentou acessar (<b>{props.location.pathname}</b>) não existe. </span></Page>)
// }
